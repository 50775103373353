import moment from 'moment';
import dayjs from 'dayjs';

import { listAllDeliveries } from 'services/shipments';
import {
  NEW_ORDERS_STATE_CODES,
  PICKUP_CANCELLATION_REASON,
  PICKUP_CANCELLATION_REASON_DESCRIPTION,
  PICKUP_STATUS
} from 'constants/pickups';
import { LOCALE } from 'constants/intl-wrapper';
import { formatAreaZone } from 'utils/shipments';
import { dates } from 'utils/helpers';
import {
  getCountryWeekendDays,
  isPickupFeesApplicable
} from 'constants/countries/countries-mapping';
import { getLocaleFromLocalStorage } from 'utils/intl-wrapper';
import { BUSINESS_TIERS } from 'constants/business';

import { notify } from 'components/Notify/Notify';
import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const pickupsStatus = {
  created: ['Requested'],
  out_for_pickup: ['Route Assigned', 'Picking up'],
  completed: ['Picked up'],
  canceled: ['Canceled']
};

export const mapCancelReason = {
  'لم استطيع الوصول للبيزنس': 'business_unreachable',
  "Can't reach business location": 'business_unreachable',
  'Rescheduled as per business request': 'business_reschedule',
  'تم التاجيل بناء على طلب البيزنس': 'business_reschedule',
  'Orders have no tracking numbers': 'orders_no_tn',
  'الاوردرات بدون رقم تتبع': 'orders_no_tn',
  'البيزنس يحتاج فلاير': 'business_flyer',
  'Business has no packing': 'business_flyer',
  'Orders are not ready for pickup': 'orders_not_ready',
  'الاوردرات غير جاهزة': 'orders_not_ready',
  'Failed to pickup orders': 'orders_failed',
  'تم الفشل فى استلام الاوردرات': 'orders_failed',
  'The business canceled the pickup': 'business_cancelled',
  'البيزنس قام بالالغاء': 'business_cancelled'
};

export const mapStateToStatus = (state) => {
  return Object.keys(pickupsStatus).find((key) =>
    pickupsStatus[key].includes(state)
  );
};

export const disabledDate = (
  current,
  date,
  pickupCutoffTime,
  holidaysDates
) => {
  const hours = date?.hour();
  const countryWeekendDays = getCountryWeekendDays() || [];
  const isWeekendDay = countryWeekendDays.includes(dayjs(current).day());

  const isHoliday = holidaysDates?.some((holiday) =>
    current.isSame(holiday, 'day')
  );

  if (isHoliday || isWeekendDay) {
    return true;
  }

  if (hours >= pickupCutoffTime) {
    return current && current < date.endOf('day');
  } else return current && current < date.startOf('day');
};

export const UTCWithZeroTime = (date) => {
  if (moment.isMoment(date)) {
    return date.format('YYYY-MM-DD');
  } else {
    return moment.utc(date).format('YYYY-MM-DD');
  }
};

export const setPickupFlag = async ({ skipRequest } = {}) => {
  try {
    let canCreatePickup = skipRequest;

    if (!skipRequest) {
      const { count } = await listAllDeliveries({
        stateCodes: NEW_ORDERS_STATE_CODES,
        limit: 1,
        createdAtStart: dayjs().subtract(30, 'day').startOf('day').format(),
        createdAtEnd: dayjs().endOf('day').format()
      });

      canCreatePickup = count > 0;
    }

    localStorage.setItem('canCreatePickup', canCreatePickup);
  } catch (error) {
    notify({ msg: error.message, error });
  }
};

export const getPickupAddress = (address) => {
  const city =
    getLocaleFromLocalStorage() === LOCALE.EN
      ? address?.city?.name
      : address?.city?.nameAr;
  const area = formatAreaZone(address);
  const zone =
    getLocaleFromLocalStorage() === LOCALE.AR && address.zone?.nameAr
      ? address?.zone?.nameAr
      : address?.zone?.name;
  const district =
    getLocaleFromLocalStorage() === LOCALE.AR && address.district?.nameAr
      ? address?.district?.nameAr
      : address?.district?.name;

  return {
    fullAddress: address,
    city,
    area,
    zone,
    district
  };
};

export const mapState = (
  state,
  scheduledDate,
  cancelReason,
  exceptionCode,
  isRescheduled = false
) => {
  switch (state) {
    case PICKUP_STATUS.REQUESTED:
      if (isRescheduled) {
        return {
          label: 'rescheduled'
        };
      }
      return {
        label: 'created',
        tooltipContent: fmt(
          {
            id: `pickups.pickup_info.table_cells.pickup_tooltips.created`
          },
          {
            pickupDate: dates(scheduledDate, 'ddd DD MMM')
          }
        )
      };
    case PICKUP_STATUS.CANCELED:
      return {
        label: 'canceled',
        tooltipTitle:
          (cancelReason || exceptionCode) &&
          (exceptionCode && PICKUP_CANCELLATION_REASON[exceptionCode]
            ? PICKUP_CANCELLATION_REASON[exceptionCode]
            : cancelReason && cancelReason.includes('_')
            ? fmt({
                id: `pickups.cancel_reasons.${cancelReason}`
              })
            : cancelReason && mapCancelReason[cancelReason]
            ? fmt({
                id: `pickups.cancel_reasons.${mapCancelReason[cancelReason]}`
              })
            : cancelReason && cancelReason),
        tooltipContent:
          exceptionCode && PICKUP_CANCELLATION_REASON[exceptionCode]
            ? PICKUP_CANCELLATION_REASON_DESCRIPTION[exceptionCode]
            : fmt({
                id: `pickups.pickup_info.table_cells.pickup_tooltips.canceled`
              })
      };
    case PICKUP_STATUS.EXCEPTION:
      return {
        label: 'exception',
        tooltipContent: fmt({
          id: `pickups.pickup_info.table_cells.pickup_tooltips.exception`
        })
      };
    case PICKUP_STATUS.PICKEDUP:
      return {
        label: 'picked_up',
        tooltipContent: fmt({
          id: `pickups.pickup_info.table_cells.pickup_tooltips.completed`
        })
      };
    case PICKUP_STATUS.ROUTE_ASSIGNED:
    case PICKUP_STATUS.PICKINGUP: {
      return {
        label: 'out_for_pickup',
        tooltipContent: fmt({
          id: `pickups.pickup_info.table_cells.pickup_tooltips.out_for_pickup`
        })
      };
    }
    default:
      return {
        label: 'created',
        tooltipContent: fmt(
          {
            id: `pickups.pickup_info.table_cells.pickup_tooltips.created`
          },
          {
            pickupDate: dates(scheduledDate, 'ddd DD MMM')
          }
        )
      };
  }
};

export const checkIfPickupFeesIsApplicable = (businessInfo) => {
  // TEMPORARY //
  const APPLICABLE_TIERS = [
    'xstNYnWa4E3vMjWEJk1OT',
    'epOFDN3OdNTccVr9anwCc',
    '4bDA3Ui7ZN39oshpl3xic',
    'wpNt3uDZwZfUUgVjlUmFL'
  ];

  return (
    isPickupFeesApplicable() &&
    businessInfo?.businessTier === BUSINESS_TIERS.BRONZE &&
    APPLICABLE_TIERS.includes(businessInfo?.pricingTier?._id)
  );
};

export const handleDateAccordingToServer = (serverTimeData, data) => {
  return serverTimeData?.timeZone?.utcOffset
    ? dayjs(data?.value).utcOffset(serverTimeData?.timeZone?.utcOffset)
    : dayjs(data?.value);
};
