import { COUNTRY_FULLNAME, DEFAULT_COUNTRY } from './constants/countries';
import { ATTEMPT_TYPES, MAX_NUM_OF_ATTEMPTS } from './constants/attempts';
import {
  IBAN_LENGTH,
  IBAN_PLACEHOLDER,
  IBAN_REGEX
} from './constants/bank-details';
import {
  IDENTITY_NUMBER_ERROR,
  IDENTITY_NUMBER_REGEX,
  PHONE_NUMBER_MAX_LENGTH
} from './constants/validations';
import { IMAGES_LIST } from './constants/sllr-empty-state';
import {
  CURRENCY,
  FAWRY_AVAILABILITIY,
  PAYMOB_AVAILABILITY,
  BOSTA_CAPITAL_AVAILABILITY,
  CIRCLE_K_AVAILABLITITY,
  CAN_BUY_EXTRA_FLYERS,
  MOYASAR_AVAILABILITY,
  BANK_RECHARGE_AVAILABILITY,
  SHOW_INTERNATIONAL_BANKS,
  ADD_SECOND_BANK,
  SHOW_CASHOUT_FEES
} from './constants/finance';
import {
  AWB_LANGUAGE,
  DEFAULT,
  ORDERS_SEARCH_PHONE_NUMBER_REGEX,
  PHONE_NUMBER_REGEX,
  WITHOUT_COUNTRY_CODE,
  ENGLISH_LETTERS_RULE_APPLICABLE,
  PICKUP_SCHEDULE_TIME_FOR_SAME_DAY,
  COUNTRY_PHONE_PLACEHOLDER,
  CITY_AREA_PLACEHOLDER_ID,
  IS_SHOP_AVAILABLE,
  NATIONAL_ID_COUNT,
  INTERNATIONAL_SHIPPING,
  DOMESTIC_SHIPPING,
  ADD_HYPHENS_TO_IDENTITY_NUMBER,
  WEEKEND_DAYS,
  PACK_PRICE_AVAILABILITY,
  BETA_FUNNEL_AVAILABILITY
} from './constants/common';
import {
  IS_SMART_STICKERS_AVAILABLE,
  SMART_FLYERS_KEY,
  SMART_FLYERS_AVAILABLE,
  MULTI_PACKAGE_AVAILABLE
} from './constants/orders';
import { SHOW_INSTANT_CASHOUT_PLAN } from './constants/pricing';
import {
  UPLOAD_FXF_ORDERS_TEMPLATE,
  SMART_UPLOAD_FXF_TEMPLATE
} from './constants/fulfillment';
import { OPEN_PACKAGE_FEES_AVAILABILTY } from 'constants/common';
import { isDashboardArabic } from 'utils/intl-wrapper';
import {
  SMART_MASSUPLOAD_COLUMNS,
  SMART_UPLOAD_DEFAULT_TEMPLATE
} from './constants/massupload';
import {
  HIDE_INTERNATIONAL_TOGGLE,
  INTERNATIONAL_SHIPPING_DESTINATION_COUNTRIES,
  RETURN_TO_FULFILLMENT,
  RETURN_TO_ORIGIN,
  SHOW_INTERNATIONAL_WALLET_EXCHANGE_RATE,
  SMART_UPLOAD_INTERNATIONAL_TEMPLATE
} from './constants/international-orders';
import { COUNTRIES } from 'constants/country-data';
import { GAZA_DONATION_AVAILABILITIY } from './constants/donations';
import { PICKUP_FEES_APPLICABLE } from 'constants/countries/constants/pickups';

const countryCode = JSON.parse(localStorage.getItem('userInfo'))?.user?.country
  ?.code;

const retryToGetUserCountryCode = () => {
  const fetchCountryCodeAgain = JSON.parse(localStorage.getItem('userInfo'))
    ?.user?.country?.code;
  const currentUserCountry =
    COUNTRIES.find(({ codeName }) => fetchCountryCodeAgain === codeName) ||
    DEFAULT_COUNTRY;
  return currentUserCountry.codeName;
};

const currentUserCountry =
  COUNTRIES.find(({ codeName }) => countryCode === codeName) || DEFAULT_COUNTRY;
const currentUserCountryCode = currentUserCountry.codeName;
export const getCurrentUserCountryData = () => {
  return currentUserCountry;
};

/* Attempts */
export const getAttemptsTypes = () => {
  return ATTEMPT_TYPES;
};

export const getMaxNumOfAttempts = () => {
  const { DELIVERY, PICKUP, RETURN } =
    MAX_NUM_OF_ATTEMPTS[currentUserCountryCode] || MAX_NUM_OF_ATTEMPTS.DEFAULT;

  return {
    DELIVERY,
    PICKUP,
    RETURN
  };
};

export const isMaxNumOfAttempts = (numOfAttempts) => {
  const { DELIVERY, PICKUP, RETURN } = getMaxNumOfAttempts();

  return {
    DELIVERY: (numOfAttempts = DELIVERY),
    PICKUP: (numOfAttempts = PICKUP),
    RETURN: (numOfAttempts = RETURN)
  };
};

/* common */
export const getAWBLanguage = () => {
  return AWB_LANGUAGE[currentUserCountryCode] || AWB_LANGUAGE.DEFAULT;
};

export const getPhoneNumberMaxLength = () => {
  return PHONE_NUMBER_MAX_LENGTH[currentUserCountryCode];
};

export const getCurrency = (cod, countryCode) => {
  return CURRENCY(cod)[countryCode || currentUserCountryCode];
};

export const getPhoneNumberRegexForDigits = (numOfDigits) => {
  const countries = {
    EG: `^(\\+20|0)1([0-2]|5)[0-9]{2,}`,
    SA: `^(\\+966|0)?[0-9]{4,}`,
    QA: `^(\\+974)?[3457]\d{7}`
  };

  return new RegExp(countries[currentUserCountryCode]);
};

export const getPhoneNumberRegex = ({
  countryCode = currentUserCountryCode,
  withoutCountryCode
}) => {
  return PHONE_NUMBER_REGEX[countryCode][
    withoutCountryCode ? WITHOUT_COUNTRY_CODE : DEFAULT
  ];
};

export const ordersPhoneNumberSearchRegex =
  ORDERS_SEARCH_PHONE_NUMBER_REGEX[currentUserCountryCode] ||
  ORDERS_SEARCH_PHONE_NUMBER_REGEX.DEFAULT;

export const getPickupScheduleTimeForSameDay = () =>
  PICKUP_SCHEDULE_TIME_FOR_SAME_DAY[currentUserCountryCode] ||
  PICKUP_SCHEDULE_TIME_FOR_SAME_DAY.DEFAULT;

export const getPhonePlaceholder = (countryCode) => {
  return COUNTRY_PHONE_PLACEHOLDER[countryCode || currentUserCountryCode];
};

export const getCityAreaPlaceholder = (countryCode) => {
  return (
    CITY_AREA_PLACEHOLDER_ID[countryCode || currentUserCountryCode] ||
    CITY_AREA_PLACEHOLDER_ID[DEFAULT_COUNTRY.codeName]
  );
};

export const getIdentityNumberRegex = () => {
  return IDENTITY_NUMBER_REGEX[currentUserCountryCode];
};

export const getInvalidIdentityNumberError = () => {
  return IDENTITY_NUMBER_ERROR[currentUserCountryCode];
};

/* Bank Details */
export const getIBANLength = () => {
  return (
    IBAN_LENGTH[currentUserCountryCode] || IBAN_LENGTH[DEFAULT_COUNTRY.codeName]
  );
};

export const getIBANPlaceholder = () => {
  return (
    IBAN_PLACEHOLDER[currentUserCountryCode] ||
    IBAN_PLACEHOLDER[DEFAULT_COUNTRY.codeName]
  );
};

export const getIbanRegex = () => {
  return IBAN_REGEX[currentUserCountryCode];
};

// Currency
export const getUserCurrency = (cod) => {
  if (cod !== undefined) {
    return CURRENCY(cod)[currentUserCountryCode];
  }
  return CURRENCY()[currentUserCountryCode].type;
};

export const isFawryAvailable = () => {
  return FAWRY_AVAILABILITIY[currentUserCountryCode];
};

export const isEnglishLettersRuleApplicable = () => {
  return ENGLISH_LETTERS_RULE_APPLICABLE[currentUserCountryCode];
};

export const isPayMobAvailable = () => {
  return PAYMOB_AVAILABILITY[currentUserCountryCode];
};

export const isMoyasarAvailable = () => {
  return MOYASAR_AVAILABILITY[currentUserCountryCode];
};

export const isBankRechargeAvailable = () => {
  return BANK_RECHARGE_AVAILABILITY[currentUserCountryCode];
};

/* sllr empty state */
export const getEmptyStateImage = (image) => {
  return (
    IMAGES_LIST[currentUserCountryCode]?.[image] ||
    IMAGES_LIST[DEFAULT_COUNTRY.codeName][image]
  );
};

export const isUserAllowedToShowAccountLockedModal = () => {
  const allowCheckForNegativeBalance = JSON.parse(
    localStorage.getItem('userInfo')
  )?.user?.country?.allowCheckForNegativeBalance;
  return allowCheckForNegativeBalance !== undefined &&
    allowCheckForNegativeBalance !== null
    ? allowCheckForNegativeBalance
    : true;
};

export const isBostaCapitalAvailable = () => {
  return (
    BOSTA_CAPITAL_AVAILABILITY[currentUserCountryCode] &&
    !window?.location?.host?.includes('dashboard.sllr')
  );
};

export const isCircleKAvailable = () => {
  return CIRCLE_K_AVAILABLITITY[currentUserCountryCode];
};

export const canBuyExtraFlyersThanTheLimit = () => {
  return CAN_BUY_EXTRA_FLYERS[currentUserCountryCode];
};

export const isInstantCashoutPlanAvailable = () => {
  if (countryCode) {
    return SHOW_INSTANT_CASHOUT_PLAN[currentUserCountryCode];
  } else {
    return SHOW_INSTANT_CASHOUT_PLAN[retryToGetUserCountryCode()];
  }
};

export const getSmartFlyersLocalizationId = (key) => {
  return SMART_FLYERS_KEY(key)[currentUserCountryCode];
};

export const isBostaShopAvailable = () => {
  return IS_SHOP_AVAILABLE[currentUserCountryCode];
};

export const isSmartStickersAvailable = () => {
  return IS_SMART_STICKERS_AVAILABLE[currentUserCountryCode];
};

/* Fulfillment */
export const getUploadFXFOrdersTemplate = () => {
  return UPLOAD_FXF_ORDERS_TEMPLATE[currentUserCountryCode];
};

export const getSmartUploadFXFTemplate = () => {
  const { AR, EN } =
    SMART_UPLOAD_FXF_TEMPLATE[currentUserCountryCode] ||
    SMART_UPLOAD_FXF_TEMPLATE.DEFAULT;

  return isDashboardArabic() ? AR : EN;
};

export const handleInternationalBanks = () => {
  return SHOW_INTERNATIONAL_BANKS[currentUserCountryCode];
};

// Donations

export const isDonationsAvailable = () => {
  return GAZA_DONATION_AVAILABILITIY[currentUserCountryCode];
};

export const isSmartFlyersAvailable = () => {
  return SMART_FLYERS_AVAILABLE[currentUserCountryCode];
};

export const getNationalIdMaxCount = () => {
  return NATIONAL_ID_COUNT[currentUserCountryCode];
};

export const isMultiPackageAvailable = () =>
  MULTI_PACKAGE_AVAILABLE[currentUserCountryCode] ||
  MULTI_PACKAGE_AVAILABLE.DEFAULT;

export const isOpenPackageFeesAvailable = () => {
  return OPEN_PACKAGE_FEES_AVAILABILTY[currentUserCountryCode];
};

export const getDefaultSmartMassuploadExcelColumns = () => {
  return (
    SMART_MASSUPLOAD_COLUMNS[currentUserCountryCode] ||
    SMART_MASSUPLOAD_COLUMNS.DEFAULT
  );
};

export const getSmartUploadDefaultTemplate = () => {
  const { AR, EN } =
    SMART_UPLOAD_DEFAULT_TEMPLATE[currentUserCountryCode] ||
    SMART_UPLOAD_DEFAULT_TEMPLATE.DEFAULT;

  return isDashboardArabic() ? AR : EN;
};

export const shouldAddHyphensToIdentityNumber = () => {
  return ADD_HYPHENS_TO_IDENTITY_NUMBER[currentUserCountryCode];
};

export const canAddSecondBank = () => {
  return ADD_SECOND_BANK[currentUserCountryCode];
};

export const getFallBackCountryName = () => {
  return COUNTRY_FULLNAME[currentUserCountryCode].en;
};

export const isInternationalShippingAvailable = () => {
  return INTERNATIONAL_SHIPPING[currentUserCountryCode];
};

export const isDomesticShippingAvailable = () => {
  return DOMESTIC_SHIPPING[currentUserCountryCode];
};

export const isSllrPayInCountry = (method) => {
  return method[currentUserCountryCode];
};

export const isReturnToFulfillmentAvailable = () => {
  return RETURN_TO_FULFILLMENT[currentUserCountryCode];
};

export const isReturnToOriginAvailable = () => {
  return RETURN_TO_ORIGIN[currentUserCountryCode];
};

export const getCountryWeekendDays = () => {
  return WEEKEND_DAYS[currentUserCountryCode];
};

export const isPackPriceAvailable = () => {
  return PACK_PRICE_AVAILABILITY[currentUserCountryCode] || false;
};

export const getSmartUploadInternationalTemplate = () => {
  const { AR, EN } =
    SMART_UPLOAD_INTERNATIONAL_TEMPLATE[currentUserCountryCode] ||
    SMART_UPLOAD_INTERNATIONAL_TEMPLATE.DEFAULT;

  return isDashboardArabic() ? AR : EN;
};

export const isBetaFunnelAvailable = (countryCode) => {
  return BETA_FUNNEL_AVAILABILITY[countryCode || currentUserCountryCode];
};

export const getInternationalShippingDestinationCountries = () => {
  return (
    INTERNATIONAL_SHIPPING_DESTINATION_COUNTRIES[currentUserCountryCode] || []
  );
};

export const isPickupFeesApplicable = () => {
  return PICKUP_FEES_APPLICABLE[currentUserCountryCode];
};

export const isShowInternationalExchangeRate = () => {
  return SHOW_INTERNATIONAL_WALLET_EXCHANGE_RATE[currentUserCountryCode];
};

export const isInternationalToggleInvisiable = () => {
  return HIDE_INTERNATIONAL_TOGGLE[currentUserCountryCode] || false;
};

export const showCashoutFees = () => {
  return SHOW_CASHOUT_FEES[currentUserCountryCode] || SHOW_CASHOUT_FEES.DEFAULT;
};
