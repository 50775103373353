import React, { createContext, useEffect } from 'react';
import dayjs from 'dayjs';

import { DEFAULT_PICKUP_CUTOFF_TIME, HOURS_IN_A_DAY } from 'constants/pickups';
import { getCountryTimeZone } from 'services/cities';

import { notify } from 'components/Notify/Notify';

export const DatesWrapper = createContext({});

export const DatesProvider = ({
  children,
  pickupCutoffTime,
  setPickupCutoffTime,
  serverTimeData,
  setServerTimeData
}) => {
  const userCountry = JSON.parse(localStorage.getItem('userInfo'))?.user
    ?.country?._id;

  const checkOnUserCountryTimeZone = async () => {
    try {
      const { timeZone, latestPickupHourInUtc } = await getCountryTimeZone();
      let formattedPickupCutoffTime = DEFAULT_PICKUP_CUTOFF_TIME;

      if (latestPickupHourInUtc > HOURS_IN_A_DAY) {
        formattedPickupCutoffTime = latestPickupHourInUtc;
      } else if (latestPickupHourInUtc !== undefined) {
        formattedPickupCutoffTime = dayjs()
          .utc()
          .hour(latestPickupHourInUtc)
          .utcOffset(timeZone.utcOffset)
          .hour();
      }

      setPickupCutoffTime(formattedPickupCutoffTime);
      setServerTimeData({
        timeZone
      });
    } catch (error) {
      notify({ msg: error.message, error });
    }
  };

  useEffect(() => {
    if (userCountry) {
      checkOnUserCountryTimeZone();
    }
  }, [userCountry]);

  return (
    <DatesWrapper.Provider
      value={{
        pickupCutoffTime,
        serverTimeData
      }}
    >
      {children}
    </DatesWrapper.Provider>
  );
};
