import React, { useEffect, useCallback, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Spin } from 'antd';

import { isRegisteredViaBetaFunnel } from 'utils/helpers';
import { getSignInPath } from 'utils/login';
import { getSignUpPath } from 'utils/signup';
import { sendSegment } from 'utils/segment';
import { ContextWrapper } from 'contexts/wrapper.context';
import {
  confirmVerifyEmail,
  confirmChangeEmail,
  oneTimeLogin
} from 'services/auth';
import {
  searchPersonId,
  checkTokenPipeDrive,
  updatePerson,
  updateDeals
} from 'services/PipeDrive';
import { EmailVerify, TYPE_LITE } from 'constants/pipe-drive';

import { notify } from 'components/Notify/Notify';

import './VerifyEmail.less';

const emailRegex = new RegExp('[\\?&]emailToken=([^&#]*)');
const tokenRegex = new RegExp('[\\?&]token=([^&#]*)');

const VerifyEmail = (props) => {
  const {
    intl,
    history,
    location: { search, state: { email } = {} }
  } = props;

  const personId =
    localStorage.getItem('personId') !== 'undefined'
      ? JSON.parse(localStorage.getItem('personId'))
      : null;
  const dealId =
    localStorage.getItem('dealId') !== 'undefined'
      ? JSON.parse(localStorage.getItem('dealId'))
      : null;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const signInPath = getSignInPath();

  const { setUserFullData } = useContext(ContextWrapper);

  const fetchChangeEmail = useCallback(async () => {
    try {
      const results = emailRegex.exec(search);
      if (results) {
        const token = decodeURIComponent(results[1].replace(/\+/g, ' '));
        const response = await confirmChangeEmail({
          verificationToken: token
        });
        notify({ msg: response.message, type: 'success' });
        if (response.data) {
          const { oldEmail, newEmail } = response.data;
          if (!userInfo) {
            const personId = await checkTokenPipeDrive(
              searchPersonId,
              oldEmail.toLowerCase().trim()
            );
            await updatePerson(personId, {
              email: newEmail
            });
          } else {
            updatePerson(personId, {
              email: newEmail
            });
          }
        }
      }
      localStorage.clear();
    } catch (error) {
      notify({ msg: error.message, error });
    }
    history.push({
      pathname: signInPath,
      search: search
    });
  }, [history, personId, search, userInfo]);

  const handleOneTimeLogin = (authToken) => {
    oneTimeLogin({ authToken })
      .then((res) => {
        localStorage.setItem('userInfo', JSON.stringify(res));
        localStorage.setItem('newToken', res.token);
        window.location.reload();
      })
      .catch((error) => {
        history.push(getSignInPath());
      });
  };

  const fetchData = async (results) => {
    try {
      const token = decodeURIComponent(results[1].replace(/\+/g, ' '));
      await confirmVerifyEmail({ token });
      sendSegment('Step0_Email verified');
      if (personId && dealId && !userInfo.user.businessAdminInfo) {
        await updatePerson(personId, {
          [EmailVerify]: 'yes'
        });

        updateDeals(dealId, {
          stage_id:
            userInfo.user.monthlyShipmentVolume === TYPE_LITE ? 107 : 112
        });
      } else if (personId && userInfo.user.businessAdminInfo) {
        await updatePerson(personId, {
          [EmailVerify]: 'yes'
        });
      }
      userInfo.user.emails[0] = {
        ...userInfo.user.emails[0],
        verified: true
      };

      setUserFullData((prev) => ({
        ...prev,
        email: { ...prev.email, verified: true }
      }));

      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      history.push({
        pathname: isRegisteredViaBetaFunnel() ? '/' : '/business-information',
        state: {
          showSuccessBanner: true,
          fromVerifyEmail: true
        }
      });
    } catch (error) {
      notify({ msg: error.message, error });
      history.push({
        pathname: isRegisteredViaBetaFunnel() ? '/' : '/business-information',
        state: {
          fromVerifyEmail: true
        }
      });
    }
  };

  useEffect(() => {
    sendSegment('E_EMAIL_VERIFICATION_PAGE_VIEWED', {
      email: email ? email : null
    });
  }, [email]);

  useEffect(() => {
    localStorage.removeItem('tempVerifyToken');
    const authToken = new URLSearchParams(window.location.search).get(
      'authToken'
    );
    if (!userInfo) {
      authToken
        ? handleOneTimeLogin(authToken)
        : window.location.replace(signInPath);
    } else if (
      !userInfo?.user?.plannedPaymentFrequency ||
      !userInfo?.user?.plannedShipmentType
    ) {
      notify({
        msg: intl.formatMessage({
          id: 'sign_up.email_verification.finish_signup'
        })
      });
      history.push(getSignUpPath());
    } else if (userInfo?.user?.emails[0]?.verified) {
      notify({
        msg: intl.formatMessage({
          id: 'sign_up.email_verification.already_verified'
        })
      });
      history.push({
        pathname: isRegisteredViaBetaFunnel() ? '/' : '/business-information',
        state: {
          fromVerifyEmail: true
        }
      });
    } else if (search) {
      const results = tokenRegex.exec(search);
      if (results !== null) {
        fetchData(results);
      } else {
        const emailResults = emailRegex.exec(search);
        if (emailResults) {
          fetchChangeEmail();
        }
      }
    }
  }, []);

  return !!emailRegex.exec(search) || !!tokenRegex.exec(search) ? (
    <Spin className="verify-email-spinner" />
  ) : (
    <></>
  );
};
export default injectIntl(withRouter(VerifyEmail));
