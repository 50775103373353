import { bostaHttp } from 'http.js';
import { downloadHeader } from 'utils/download';

export const fetchPickups = (payload) => {
  return bostaHttp.get(`/pickups/search`, payload);
};

export const fetchPickup = (id) => {
  return bostaHttp.get(`/pickups/${id}`);
};

export const createPickup = (payload) => {
  return bostaHttp.post(`/pickups`, payload);
};

export const createOrderPickup = (payload) => {
  return bostaHttp.post(`/deliveries/business/link-pickup`, payload);
};

export const editPickup = ({ id, payload }) => {
  return bostaHttp.put(`/pickups/${id}`, payload);
};

export const fetchPickupLocations = (payload) => {
  return bostaHttp.get(`/pickup-locations`, payload);
};

export const fetchPickupLocation = (id, payload) => {
  return bostaHttp.get(`/pickup-locations/${id}`, payload);
};

export const createPickupLocation = (payload) => {
  return bostaHttp.post(`/pickup-locations`, payload);
};

export const cancelPickup = (payload) => {
  return bostaHttp.post('/pickups/cancel', payload);
}

export const createContacts = ({ id, payload }) => {
  return bostaHttp.put(`/pickup-locations/${id}`, payload);
};

export const exportedPickupsDetailsFile = (id, payload) => {
  return bostaHttp.get(
    `/pickups/${id}/report/details`,
    payload,
    downloadHeader
  );
};

export const exportedPickupsFile = ({ payload }) => {
  return bostaHttp.get('/pickups/xlsx', payload);
};

export const getDefaultContact = (payload) => {
  return bostaHttp.get('/businesses/get-default-contact', payload);
};

export const updateDefaultContact = (payload) => {
  return bostaHttp.put('/businesses/edit-default-contact', payload);
};

export const getHolidays = (payload) => {
  return bostaHttp.get('/sla/holidays', payload);
};

export const ratePickup = ({ pickupId, payload }) => {
  return bostaHttp.post(`/pickups/${pickupId}/rating`, payload);
};

export const reschedulePickup = ({ pickupId, payload }) => {
  return bostaHttp.post(`/pickups/${pickupId}/reschedule`, payload);
};