import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

import { COUNTRIES } from 'constants/country-data';
import {
  checkSmartDate,
  getCurrentUserCountryName,
} from 'utils/helpers';

import { fmt } from 'components/IntlWrapper/IntlWrapper';

dayjs.extend(utc);
dayjs.extend(tz);

export const PICKUP_STATUS = {
  REQUESTED: 'Requested',
  ROUTE_ASSIGNED: 'Route Assigned',
  PICKINGUP: 'Picking up',
  PICKEDUP: 'Picked up',
  CANCELED: 'Canceled',
  EXCEPTION: 'Exception'
};

export const REPEATED_TYPE_SELECTION = [
  { label: 'Every Day', value: 'Daily' },
  { label: 'Every Week', value: 'Weekly' }
];

export const DAYS = [
  { label: 'saturday', labelAr: 'السبت', value: 'Saturday' },
  { label: 'sunday', labelAr: 'الحد', value: 'Sunday' },
  { label: 'monday', labelAr: 'الأثنين', value: 'Monday' },
  { label: 'tuesday', labelAr: 'الثلاثاء', value: 'Tuesday' },
  { label: 'wednesday', labelAr: 'الأربعاء', value: 'Wednesday' },
  { label: 'thursday', labelAr: 'الخميس', value: 'Thursday' }
];

export const WEEKLY_VALUE = 'Weekly';
export const REPEATED_TYPE_ONE_TIME = 'One Time';

export const CREATED = 'created';
export const OUT_FOR_PICKUP = 'out_for_pickup';
export const REQUESTED = 'in_progress';
export const COMPLETED = 'completed';
export const CANCELED = 'canceled';
export const RESCHEDULED = 'rescheduled';

export const upcomingMaskedPickupsStatus = [
  {
    label: fmt({
      id: 'pickups.pickup_info.table_cells.pickups_status.created'
    }),
    value: 'Requested'
  },
  {
    label: fmt({
      id: 'pickups.pickup_info.table_cells.pickups_status.out_for_pickup'
    }),
    value: ['Route Assigned', 'Picking up']
  }
];

export const historyMaskedPickupsStatus = [
  {
    label: fmt({
      id: 'pickups.pickup_info.table_cells.pickups_status.completed'
    }),
    value: 'Picked up'
  },
  {
    label: fmt({
      id: 'pickups.pickup_info.table_cells.pickups_status.canceled'
    }),
    value: 'Canceled'
  }
];

export const pickupsStatusColors = {
  out_for_pickup: 'br-state-out-for-pickup',
  created: 'br-state-created',
  completed: 'br-state-completed',
  canceled: 'br-state-canceled'
};

export const PICKUP_PHONE_NUMBER_MAX_LENGHT =
  JSON.parse(localStorage.getItem('userInfo'))?.user?.country?.code ===
  COUNTRIES[1].codeName
    ? 9
    : 11;

const SILVER = 'Silver';
const GOLD = 'Gold';
const RED = 'Red';
export const BULKY = 'Bulky';

export const PICKUP_PACKAGE_TYPE = {
  NORMAL: 'Normal',
  HEAVY_BULKY: 'Heavy Bulky',
  LIGHT_BULKY: 'Light Bulky'
};

export const COUNTRY_POINT_MAP =
  JSON.parse(localStorage.getItem('userInfo'))?.user?.country?.code ===
  COUNTRIES[1].codeName
    ? { lat: 21.489066, lng: 39.183054 }
    : { lat: 29.9563293, lng: 31.2720199 };

export const NEW_ORDERS_STATE_CODES = '10:F,E,S';

export const UPCOMING_PICKUP_STATES =
  'Requested,Arrived at business,Route Assigned,Picking up,Receiving';
export const HISTORY_PICKUP_STATES = 'Canceled,Picked up';

export const QUICK_FILTERS = [
  {
    label: fmt({
      id: 'new_pickups.pickup_listing.quick_filters.upcoming_pickups'
    }),
    value: '/pickups/upcoming'
  },
  {
    label: fmt({
      id: 'new_pickups.pickup_listing.quick_filters.history_pickups'
    }),
    value: '/pickups/history'
  }
];

export const UPCOMING_COLUMNS = [
  {
    title: fmt({ id: 'new_pickups.pickup_listing.table_headers.pickup_id' }),
    dataIndex: 'trackingId',
    fixed: 'left',
    width: 164
  },
  {
    title: fmt({ id: 'new_pickups.pickup_listing.table_headers.location' }),
    dataIndex: 'tableLocation',
    width: 164
  },
  {
    title: fmt({
      id: 'new_pickups.pickup_listing.table_headers.contact_person'
    }),
    dataIndex: 'contactPerson',
    width: 156,
    render: (contactPerson) => {
      return (
        <div className="br-pickups-table__contact">
          <>
            <span>
              {contactPerson?.name ||
                fmt({
                  id: 'common.empty_field'
                })}
            </span>
            <span>
              {contactPerson?.phone ||
                fmt({
                  id: 'common.empty_field'
                })}
            </span>
          </>
        </div>
      );
    }
  },
  {
    title: fmt({
      id: 'new_pickups.pickup_listing.table_headers.pickup_date'
    }),
    dataIndex: 'upcomingDate',
    width: 126
  },
  {
    title: fmt({
      id: 'new_pickups.pickup_listing.table_headers.type'
    }),
    dataIndex: ['repeatedData', 'repeatedType'],
    width: 120,
    render: (repeatedType, { repeatedData }) => {
      const type = repeatedData?.days?.length ? (
        <div className="br-pickups-table__type">
          <span>
            {fmt({
              id: `new_pickups.pickup_listing.table_columns.repeatation_types.recurring`
            })}
          </span>
          <span>
            {repeatedData.days
              .map((day) =>
                fmt({
                  id: `new_pickups.pickup_listing.table_columns.days.${day?.toLowerCase()}`
                })
              )
              .join(', ')}
          </span>
        </div>
      ) : (
        fmt({
          id: `new_pickups.pickup_listing.table_columns.repeatation_types.${repeatedType
            ?.replace(/\s+/g, '_')
            .toLowerCase()}`
        })
      );
      return type;
    }
  },
  {
    title: fmt({
      id: 'new_pickups.pickup_listing.table_headers.requested_parcels'
    }),
    dataIndex: 'numberOfParcels',
    width: 100
  },
  {
    title: fmt({ id: 'new_pickups.pickup_listing.table_headers.status' }),
    width: 130,
    dataIndex: 'stateTag'
  },
  {
    title: fmt({
      id: 'new_pickups.pickup_listing.table_headers.courier'
    }),
    dataIndex: 'star',
    width: 156,
    render: (star) => {
      return (
        <div className="br-pickups-table__contact">
          {star ? (
            <>
              <span>
                {star?.name ||
                  fmt({
                    id: 'common.empty_field'
                  })}
              </span>
              <span>
                {star?.phone ||
                  fmt({
                    id: 'common.empty_field'
                  })}
              </span>
            </>
          ) : (
            <>-</>
          )}
        </div>
      );
    }
  },
  { title: '', dataIndex: 'actions', width: 60 }
];

export const HISTORY_COLUMNS = [
  {
    title: fmt({ id: 'new_pickups.pickup_listing.table_headers.pickup_id' }),
    dataIndex: 'trackingId',
    fixed: 'left',
    width: 164
  },
  {
    title: fmt({ id: 'new_pickups.pickup_listing.table_headers.status' }),
    dataIndex: 'stateTag',
    width: 120
  },
  {
    title: fmt({ id: 'new_pickups.pickup_listing.table_headers.location' }),
    dataIndex: 'tableLocation',
    width: 164
  },
  {
    title: fmt({
      id: 'new_pickups.pickup_listing.table_headers.contact_person'
    }),
    dataIndex: 'business',
    width: 136,
    render: (contactPerson) => {
      return (
        <div className="br-pickups-table__contact">
          <span>
            {contactPerson?.name ||
              fmt({
                id: 'common.empty_field'
              })}
          </span>
          <span>
            {contactPerson?.phone ||
              fmt({
                id: 'common.empty_field'
              })}
          </span>
        </div>
      );
    }
  },
  {
    title: fmt({
      id: 'new_pickups.pickup_listing.table_headers.pickup_date'
    }),
    dataIndex: 'pickedupDate',
    width: 120,
    render: (pickedupDate) =>
      pickedupDate ? checkSmartDate(pickedupDate) : '-'
  },
  {
    title: fmt({
      id: 'new_pickups.pickup_listing.table_headers.courier'
    }),
    dataIndex: 'star',
    width: 150,
    render: (star) => {
      return (
        <div className="br-pickups-table__contact">
          {star ? (
            <>
              <span>
                {star?.name ||
                  fmt({
                    id: 'common.empty_field'
                  })}
              </span>
              <span>
                {star?.phone ||
                  fmt({
                    id: 'common.empty_field'
                  })}
              </span>
            </>
          ) : (
            <>-</>
          )}
        </div>
      );
    }
  },
  {
    dataIndex: 'numberOfParcels',
    width: 100,
    title: fmt({
      id: 'new_pickups.pickup_listing.table_headers.requested'
    })
  },
  {
    dataIndex: 'pickupsCounter',
    width: 120,
    title: fmt({
      id: 'new_pickups.pickup_listing.table_headers.picked_up'
    }),
    render: (pickupsCounter) => pickupsCounter || '-'
  },
  {
    dataIndex: 'whatsApp',
    width: 120,
    title: fmt({
      id: 'new_pickups.pickup_listing.table_headers.validated'
    })
  },
  {
    dataIndex: 'rate',
    width: 120,
    title: fmt({
      id: 'new_pickups.pickup_listing.table_headers.rating'
    })
  }
];

export const DEFAULT_TAB_PATH = 'upcoming';
export const PICKUPS_PATHS = ['upcoming', 'history'];

const PICKUPS_STATUS_MENU = (tabName) => {
  return tabName === PICKUPS_PATHS[0]
    ? [
        {
          label: fmt({
            id: `new_pickups.states.created`
          }),
          value: 'Requested'
        },
        {
          label: fmt({
            id: `new_pickups.states.out_for_pickup`
          }),
          value: 'Route Assigned,Picking up'
        }
      ]
    : [
        {
          label: fmt({ id: `new_pickups.states.picked_up` }),
          value: 'Picked up'
        },
        {
          label: fmt({ id: `new_pickups.states.canceled` }),
          value: 'Canceled'
        }
      ];
};

export const TABLE_FILTERS = (tabName, fetchPickupLocations) => [
  {
    label: fmt({ id: `new_pickups.pickup_listing.filters.pickup_date` }),
    menuType: 'date',
    key: 'scheduledAt'
  },
  {
    label: fmt({ id: `new_pickups.pickup_listing.filters.pickup_location` }),
    placeholder: fmt({
      id: 'new_pickups.pickup_listing.filters.pickup_location_placeholder'
    }),
    fetchingAPI: fetchPickupLocations,
    keyToBeSelectedFromListInEn: 'locationName',
    key: 'businessLocationId',
    className: 'br-pickup-location-filter'
  },
  {
    label: fmt({ id: `new_pickups.pickup_listing.filters.pickup_status` }),
    menu: PICKUPS_STATUS_MENU(tabName),
    key: 'state'
  }
];

export const DEFAULT_PICKUP_CUTOFF_TIME = 16;
export const HOURS_IN_A_DAY = 24;

export const PICKUP_DATE_QUICK_FILTERS = [
  {
    label: fmt({
      id: 'date_picker.filter_option.today'
    }),
    valueInDays: 0
  },
  {
    label: fmt({
      id: 'date_picker.filter_option.tomorrow'
    }),
    valueInDays: 1
  }
];

export const DATE_FORMAT = 'D MMM YYYY';
export const DATE_FORMAT_WITH_DAY = 'ddd, D MMM YYYY';

export const SELECTED_ALL_DAYS_COUNT = 6;
export const SELECTED_PICKUP_CONTACT_INDEX = 'selectedPickupContactIndex';
export const SELECTED_RETURN_CONTACT_INDEX = 'selectedReturnContactIndex';

export const PICKUP_PROOF_TYPE_OTP = 'OTP';

export const PICKUP_UPDATES_TYPE = {
  RESCHEDULED_PICKUP: 'RESCHEDULED_PICKUP',
  RECURRING_PICKUP: 'recurring'
};

export const PICKUP_UPDATE = (businessInfo = {}) => ({
  [PICKUP_UPDATES_TYPE.RESCHEDULED_PICKUP]: {
    key: 'pickupRescheduledByStar',
    title: fmt(
      { id: 'overview.pickup_updates_banner.rescheduled_pickup_banner.title' },
      {
        pickupId:
          businessInfo.featuresConfigurations?.pickupRescheduledByStar?.puid
      }
    ),
    subTitle: fmt(
      {
        id: 'overview.pickup_updates_banner.rescheduled_pickup_banner.subtitle'
      },
      {
        pickupLocation:
          businessInfo.featuresConfigurations?.pickupRescheduledByStar
            ?.locationName,
        scheduledDate: dayjs(
          businessInfo.featuresConfigurations?.pickupRescheduledByStar
            ?.oldScheduledDate
        ).format('D MMM')
      }
    ),
    secondaryButtonLabel: fmt({
      id: 'overview.pickup_updates_banner.rescheduled_pickup_banner.secondary_action'
    }),
    secondaryButtonAction: () =>
      window.open(
        `/pickups/${businessInfo.featuresConfigurations?.pickupRescheduledByStar?.puid}`,
        '_blank'
      )
  },
  [PICKUP_UPDATES_TYPE.RECURRING_PICKUP]: {
    key: 'recurringPickupUpdates',
    title: fmt(
      { id: 'overview.pickup_updates_banner.recurring_pickup_banner.title' },
      {
        pickupId:
          businessInfo.featuresConfigurations?.recurringPickupUpdates?.puid
      }
    ),
    subTitle: fmt(
      {
        id: 'overview.pickup_updates_banner.recurring_pickup_banner.subtitle'
      },
      {
        pickupLocation:
          businessInfo.featuresConfigurations?.recurringPickupUpdates
            ?.locationName,
        canceledDay: fmt({
          id: `pickups.repeated_modal.days.${
            businessInfo.featuresConfigurations?.recurringPickupUpdates?.canceledDay?.toLowerCase() ||
            'sunday'
          }`
        })
      }
    ),
    secondaryButtonLabel: fmt({
      id: 'overview.pickup_updates_banner.recurring_pickup_banner.secondary_action'
    }),
    secondaryButtonAction: () =>
      window.open(
        `/pickups/${businessInfo.featuresConfigurations?.recurringPickupUpdates?.puid}`,
        '_blank'
      )
  }
});

export const PICKUP_LOCATION_REGIONS = {
  INTERNATIONAL: 'INTERNATIONAL',
  LOCAL: 'LOCAL'
};

export const PICKUP_CANCELLATION_REASON = {
  1: fmt({
    id: 'pickups.cancel_reasons.reschedule_business_needs_change_pickup_location'
  }),
  2: fmt({ id: 'pickups.cancel_reasons.business_reschedule' }),
  3: fmt({ id: 'pickups.cancel_reasons.business_unreachable' }),
  6: fmt({ id: 'pickups.cancel_reasons.business_flyer' }),
  8: fmt({ id: 'pickups.cancel_reasons.canceled_order_size_inappropriate' }),
  9: fmt({ id: 'pickups.cancel_reasons.orders_no_tn' }),
  10: fmt({ id: 'pickups.cancel_reasons.business_cancelled' }),
  12: fmt({ id: 'pickups.cancel_reasons.address_unreachable' }),
  13: fmt({ id: 'pickups.cancel_reasons.incomplete_contact' }),
  14: fmt({ id: 'pickups.cancel_reasons.phone_not_correct' })
};

export const PICKUP_CANCELLATION_REASON_DESCRIPTION = {
  1: fmt({
    id: 'pickups.cancel_reasons_descriptions.reschedule_business_needs_change_pickup_location'
  }),
  2: fmt({ id: 'pickups.cancel_reasons_descriptions.business_reschedule' }),
  3: fmt({ id: 'pickups.cancel_reasons_descriptions.business_unreachable' }),
  6: fmt({ id: 'pickups.cancel_reasons_descriptions.business_flyer' }),
  8: fmt({
    id: 'pickups.cancel_reasons_descriptions.canceled_order_size_inappropriate'
  }),
  9: fmt({ id: 'pickups.cancel_reasons_descriptions.orders_no_tn' }),
  10: fmt({ id: 'pickups.cancel_reasons_descriptions.business_cancelled' }),
  12: fmt({ id: 'pickups.cancel_reasons_descriptions.address_unreachable' }),
  13: fmt({ id: 'pickups.cancel_reasons_descriptions.incomplete_contact' }),
  14: fmt({ id: 'pickups.cancel_reasons_descriptions.incomplete_contact' })
};

export const SHOW_PREPARE_ORDER_ACTION_REASONS = [6, 9, 8];

export const CANCELED_PICKUP_CONFIGURATION_KEY =
  'canceledPickupsWaitingForAction';

export const PICKUP_LOCATIONS_QUICK_FILTER = [
  { label: getCurrentUserCountryName(), value: 'LOCAL' },
  {
    label: fmt({ id: 'pickups.international_returns' }),
    value: 'INTERNATIONAL'
  }
];

export const MIN_CHARGABLE_PICKUPS = 3;
export const COST_PER_CHARGABLE_PICKUP = 70;

export const LOCATION_ID_FIELD_NAMES = ['locationId', 'returnLocationId'];

export const RATE_PICKUP_SERVICE_STEPS = {
  RATE_STAR: 'RATE_STAR',
  RATE_SERVICE: 'RATE_SERVICE'
};

export const MINIMUM_STARS_FOR_GOOD_RATING = 4;

export const STAR_PICKUP_RATING_CATEGORIES = {
  BAD_ATTITUDE: 'Bad Attitude',
  COMMUNICATION_ISSUE: 'Communication Issue',
  DELAY: 'Delay',
  FAKE_UPDATE: 'Fake Update',
  MISSING_ITEMS: 'Missing Items',
  LOCATION_ISSUE: 'Location Issue',
  GOOD_ATTITUDE: 'Good Attitude'
};

export const STAR_RATING_CATEGORIES = {
  BAD_RATING_CATEGORIES: [
    {
      value: STAR_PICKUP_RATING_CATEGORIES.BAD_ATTITUDE,
      label: fmt({ id: 'rate_pickup_service_modal.categories.bad_attuide' })
    },
    {
      value: STAR_PICKUP_RATING_CATEGORIES.COMMUNICATION_ISSUE,
      label: fmt({
        id: 'rate_pickup_service_modal.categories.communication_issue'
      })
    },
    {
      value: STAR_PICKUP_RATING_CATEGORIES.DELAY,
      label: fmt({
        id: 'rate_pickup_service_modal.categories.delay'
      })
    },
    {
      value: STAR_PICKUP_RATING_CATEGORIES.FAKE_UPDATE,
      label: fmt({
        id: 'rate_pickup_service_modal.categories.fake_update'
      })
    },
    {
      value: STAR_PICKUP_RATING_CATEGORIES.MISSING_ITEMS,
      label: fmt({
        id: 'rate_pickup_service_modal.categories.missing_items'
      })
    },
    {
      value: STAR_PICKUP_RATING_CATEGORIES.LOCATION_ISSUE,
      label: fmt({
        id: 'rate_pickup_service_modal.categories.location_issue'
      })
    }
  ],
  GOOD_RATING_CATEGORIES: [
    {
      value: STAR_PICKUP_RATING_CATEGORIES.GOOD_ATTITUDE,
      label: fmt({
        id: 'rate_pickup_service_modal.categories.good_attuide'
      })
    }
  ]
};

const STAR_PICKUP_RATING_REASONS = {
  NON_REPRESENTATIVE: 'Non-representative',
  RUDE_BEHAVIOR: 'Rude Behavior',
  REQUESTS_EXTRA_FEES: 'Requests Extra Fees',
  REFUSES_DOOR_DELIVERY: 'Refuses Door Delivery',
  UNEDUCATED: 'Uneducated',
  EXCESSIVE_CALLS: 'Excessive Calls',
  CALLS_AT_INCONVENIENT_TIMES: 'Calls At Inconvenient Times',
  LATE_DAY_PICKUP: 'Late Day Pickup',
  DELAYED_PICKUP_DATE: 'Delayed Pickup Date',
  LATE_PICKUP_STATUS_UPDATE: 'Late Pickup Status Update',
  PICKUP_NOT_PERFORMED_BUT_MARKED_AS_COMPLETED:
    'PickUp Not Performed But Marked As Completed',
  WRONG_NUMBER_OF_SCANNED_PARCELS: 'Wrong Number Of Scanned Parcels',
  EXCESSIVE_LOCATION_CLARIFICATION_REQUIRED:
    'Excessive Location Clarification Required',
  SOME_MISSING_ITEMS: 'Some Missing Items',
  SHIPMENTS_DAMAGED: 'Shipments Damaged',
  NEAT_AND_PROFESSIONAL_APPEARANCE: 'Neat and Professional Appearance',
  POSITIVE_ATTITUDE: 'Positive Attitude',
  QUICK_AND_EFFICIENT_SERVICE: 'Quick and Efficient Service',
  PROPER_HANDLING_OF_PACKAGES: 'Proper Handling of Packages'
};

export const STAR_RATING_REASONS = {
  [STAR_PICKUP_RATING_CATEGORIES.BAD_ATTITUDE]: [
    {
      value: STAR_PICKUP_RATING_REASONS.NON_REPRESENTATIVE,
      label: fmt({
        id: 'rate_pickup_service_modal.rating_reason.non_representative'
      })
    },
    {
      value: STAR_PICKUP_RATING_REASONS.RUDE_BEHAVIOR,
      label: fmt({
        id: 'rate_pickup_service_modal.rating_reason.rude_behavior'
      })
    },
    {
      value: STAR_PICKUP_RATING_REASONS.REQUESTS_EXTRA_FEES,
      label: fmt({
        id: 'rate_pickup_service_modal.rating_reason.requests_extra_fees'
      })
    },
    {
      value: STAR_PICKUP_RATING_REASONS.REFUSES_DOOR_DELIVERY,
      label: fmt({
        id: 'rate_pickup_service_modal.rating_reason.refuses_door_delivery'
      })
    },
    {
      value: STAR_PICKUP_RATING_REASONS.UNEDUCATED,
      label: fmt({
        id: 'rate_pickup_service_modal.rating_reason.uneducated'
      })
    }
  ],
  [STAR_PICKUP_RATING_CATEGORIES.COMMUNICATION_ISSUE]: [
    {
      value: STAR_PICKUP_RATING_REASONS.EXCESSIVE_CALLS,
      label: fmt({
        id: 'rate_pickup_service_modal.rating_reason.excessive_calls'
      })
    },
    {
      value: STAR_PICKUP_RATING_REASONS.CALLS_AT_INCONVENIENT_TIMES,
      label: fmt({
        id: 'rate_pickup_service_modal.rating_reason.call_at_inconvenient_times'
      })
    }
  ],
  [STAR_PICKUP_RATING_CATEGORIES.DELAY]: [
    {
      value: STAR_PICKUP_RATING_REASONS.LATE_DAY_PICKUP,
      label: fmt({
        id: 'rate_pickup_service_modal.rating_reason.late_day_pickup'
      })
    },
    {
      value: STAR_PICKUP_RATING_REASONS.DELAYED_PICKUP_DATE,
      label: fmt({
        id: 'rate_pickup_service_modal.rating_reason.delayed_pickup_date'
      })
    },
    {
      value: STAR_PICKUP_RATING_REASONS.LATE_PICKUP_STATUS_UPDATE,
      label: fmt({
        id: 'rate_pickup_service_modal.rating_reason.late_pickup_status_update'
      })
    }
  ],
  [STAR_PICKUP_RATING_CATEGORIES.FAKE_UPDATE]: [
    {
      value:
        STAR_PICKUP_RATING_REASONS.PICKUP_NOT_PERFORMED_BUT_MARKED_AS_COMPLETED,
      label: fmt({
        id: 'rate_pickup_service_modal.rating_reason.pickup_fake_update'
      })
    },
    {
      value: STAR_PICKUP_RATING_REASONS.WRONG_NUMBER_OF_SCANNED_PARCELS,
      label: fmt({
        id: 'rate_pickup_service_modal.rating_reason.wrong_scanned_parcels'
      })
    }
  ],
  [STAR_PICKUP_RATING_CATEGORIES.MISSING_ITEMS]: [
    {
      value: STAR_PICKUP_RATING_REASONS.SOME_MISSING_ITEMS,
      label: fmt({
        id: 'rate_pickup_service_modal.rating_reason.missing_items'
      })
    },
    {
      value: STAR_PICKUP_RATING_REASONS.SHIPMENTS_DAMAGED,
      label: fmt({
        id: 'rate_pickup_service_modal.rating_reason.damaged_items'
      })
    }
  ],
  [STAR_PICKUP_RATING_CATEGORIES.LOCATION_ISSUE]: [
    {
      value:
        STAR_PICKUP_RATING_REASONS.EXCESSIVE_LOCATION_CLARIFICATION_REQUIRED,
      label: fmt({
        id: 'rate_pickup_service_modal.rating_reason.excessive_location_clarification'
      })
    }
  ],
  [STAR_PICKUP_RATING_CATEGORIES.GOOD_ATTITUDE]: [
    {
      value: STAR_PICKUP_RATING_REASONS.NEAT_AND_PROFESSIONAL_APPEARANCE,
      label: fmt({
        id: 'rate_pickup_service_modal.rating_reason.neat_professional_appearance'
      })
    },
    {
      value: STAR_PICKUP_RATING_REASONS.POSITIVE_ATTITUDE,
      label: fmt({
        id: 'rate_pickup_service_modal.rating_reason.positive_attitude'
      })
    },
    {
      value: STAR_PICKUP_RATING_REASONS.QUICK_AND_EFFICIENT_SERVICE,
      label: fmt({
        id: 'rate_pickup_service_modal.rating_reason.quick_and_efficient'
      })
    },
    {
      value: STAR_PICKUP_RATING_REASONS.PROPER_HANDLING_OF_PACKAGES,
      label: fmt({
        id: 'rate_pickup_service_modal.rating_reason.proper_handling'
      })
    }
  ]
};
