import React from 'react';
import { message } from 'antd';
import Icon from '@ant-design/icons';
import classnames from 'classnames';

import { isUserAuth, userInfo } from 'utils/helpers';
import errorHandler from 'utils/error-reporting';

import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as Close } from 'assets/bosta-icons/Close.svg';

import './Notify.less';

export const MESSAGES_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
  ERROR: 'error'
};

const NETWORK_ERROR = 'Network Error';

let toastConfigObject = {
  duration: 3,
  maxCount: 1
};

message.config(toastConfigObject);

const configureContentShape = (msg, title, extraAction) => {
  const onCloseMessage = () => {
    message.success({
      content: 'Hide',
      className: 'br-notify-message__hidden'
    });
  };
  return (
    <div className="br-notify__message">
      <div className="br-notify__message__content">
        {title && (
          <span className="br-notify__message__title body-medium">
            {title}.
          </span>
        )}
        <span
          className={classnames('br-notify__message__subtitle', {
            'body-medium': !title,
            body: title
          })}
          data-testid="message-span"
        >
          {msg}
        </span>
      </div>
      <div className="br-notify__message__actions">
        {extraAction?.action && (
          <BRButton
            type="link-gray"
            label={extraAction?.label}
            onClick={() => {
              extraAction.action();
              onCloseMessage();
            }}
          />
        )}
        <Icon component={Close} onClick={onCloseMessage} />
      </div>
    </div>
  );
};

const controlNotificationType = ({ messageType, msg, title, extraAction }) => {
  switch (messageType) {
    case MESSAGES_TYPES.SUCCESS:
      return message.success({
        content: configureContentShape(msg, title, extraAction),
        className: MESSAGES_TYPES.SUCCESS
      });
    case MESSAGES_TYPES.WARNING:
      return message.warning({
        content: configureContentShape(msg, title, extraAction),
        className: MESSAGES_TYPES.WARNING
      });
    case MESSAGES_TYPES.INFO:
      return message.info({
        content: configureContentShape(msg, title, extraAction),
        className: MESSAGES_TYPES.INFO
      });
    case MESSAGES_TYPES.ERROR:
    default:
      return message.error({
        content: configureContentShape(msg, title, extraAction),
        className: MESSAGES_TYPES.ERROR
      });
  }
};

export const notify = ({
  msg,
  title,
  type = 'error',
  extraAction,
  error,
  status
}) => {
  if (
    type === MESSAGES_TYPES.ERROR &&
    !error?.status &&
    !status &&
    msg !== NETWORK_ERROR
  ) {
    if (
      isUserAuth() &&
      window?.env?.ERROR_REPORTING_API_KEY &&
      errorHandler.setUser
    ) {
      errorHandler.setUser(userInfo?.businessAdminInfo?.businessId);
    }
    errorHandler.report(error ? error : msg);
  }
  return controlNotificationType({
    messageType: type,
    title,
    msg,
    extraAction
  });
};
